import { useEffect, useState } from "react";
import PageContainer from "../layout/PageContainer";
import Title from "../content/content_list/Title";
import UploadFileButton from "./UploadFileButton";
import FilterBox, { ApplyFilterType } from "./FilterBox";
import { deleteFiles, getFiles } from "../../api/fileManager";
import FileManagerContent from "./FileManagerContent";
import { FileContentType } from "../../types/types";
import { Snackbar, SnackbarContent } from "@mui/material";
import AreYouSureModal from "../manage_content/AreYouSureModal";
import { Pagination, SortItemType } from "./table/Table";
import Spinner from "../Spinner";

const FileManager = () => {
  const [searchInput, setSearchInput] = useState("");
  const [loadingContent, setLoadingContent] = useState(false);
  const [pagination, setPagination] = useState<Pagination>({ totalLenght: 0 });
  const [reset, setReset] = useState(false);
  const [selectedPage, setSelectedPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(Number(localStorage.getItem(`selectedOptionFileManager`)) || 4);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState<number | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarState, setSnackbarState] = useState({ message: "", showSnackbar: false });
  const [sortItem, setSortItem] = useState<SortItemType>({ name: "", status: 0 });
  const [files, setFiles] = useState<Array<FileContentType>>([]);
  const [filterInput, setFilterInput] = useState<ApplyFilterType>({
    type: { key: "", label: "" },
    timeForm: new Date(0),
    timeTo: new Date(8640000000000000),
    inUsed: null,
    sizeFrom: "",
    sizeTo: "",
    searchInput: "",
  });
  const selectedNumber = localStorage.getItem(`selectedOptionFileManager`);
  useEffect(() => {
    const dateFrom = new Date(filterInput?.timeForm).getTime();
    const dateTo = new Date(filterInput?.timeTo).getTime();
    getFiles(
      `?${
        sortItem.status !== 0 ? `sort=${sortItem.name}&sort_type=${sortItem.status}&` : ``
      }page=${selectedPage}&page_size=${selectedNumber}&${filterInput.searchInput ? `search=${filterInput.searchInput}` : `search=`}${
        filterInput.type?.key && filterInput.type.key !== "all" ? `&type=${filterInput.type.key}` : ""
      }${dateFrom !== 0 ? `&upload_from=${dateFrom}` : ""}${dateTo !== 8640000000000000 ? `&upload_to=${dateTo}` : ""}${
        filterInput.inUsed && filterInput.inUsed.key !== "all" ? `&is_used=${filterInput.inUsed.key}` : ""
      }${filterInput.sizeFrom ? `&size_from=${filterInput.sizeFrom}` : ""}${filterInput.sizeTo ? `&size_to=${filterInput.sizeTo}` : ""}`
    ).then((payload) => {
      setPagination({ totalLenght: payload.data.count });
      if (payload.status === 200) {
        setLoadingContent(false);
        setFiles(payload.data.results);
        setLoading(false);
      }
    });
  }, [filterInput, selectedNumber, selectedPage, reset, sortItem]);

  useEffect(() => {
    setLoadingContent(true);
  }, [selectedNumber, selectedPage, sortItem]);

  const deleteHandler = (id: number) => {
    setDeleteLoading(true);
    deleteFiles(id).then((res) => {
      if (res.status === 204) {
        setDeleteLoading(false);
        setShowModal(false);
        setSnackbarState({ message: "File Deleted Successfully", showSnackbar: true });
        setReset(!reset);
      }
    });
  };
  return (
    <PageContainer>
      <Snackbar
        open={snackbarState.showSnackbar}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#03A678",
          },
          "& .MuiSnackbarContent-message": {
            fontSize: "16px",
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "100",
          },
        }}
        onClose={() => {
          setSnackbarState({ ...snackbarState, showSnackbar: false });
        }}
      >
        <SnackbarContent message={snackbarState.message} />
      </Snackbar>
      <Title
        button={
          <UploadFileButton
            fileResponseHandler={(resp) => {
              const respFile = {
                id: resp.data.id,
                isUsed: resp.data.numberOfUsage > 0 ? "true" : "false",
                name: resp.data.name,
                size: resp.data.size,
                type: resp.data.type,
                uploadDate: resp.data.uploadDate,
                url: resp.data.url,
                numberOfUsage: resp.data.numberOfUsage,
                alternativeText: resp.data.alternativeText,
                thumbnail: resp.data.thumbnail,
              };
              const newFileContainer: FileContentType[] = [respFile];
              newFileContainer.push(...files);
              setFiles(newFileContainer);
            }}
          />
        }
        title="File Manager"
        className="px-12 header-title-space"
      />
      <FilterBox
        applyButtonHandler={(input) => {
          setLoading(true);
          setFilterInput(input);
        }}
        loading={loading}
        searchHandler={(input) => setSearchInput(input)}
      />
      <div className="border border-t-0 border-b-secondary-100 border-b-8"></div>
      <FileManagerContent
        visibile={true}
        data={files}
        deleteHandler={(id) => {
          setSelectedRow(id);
          setShowModal(true);
        }}
        pagination={pagination}
        setPageSize={setPageSize}
        pageSize={pageSize}
        gotoPageHandler={setSelectedPage}
        sortHandler={(i) => setSortItem(i)}
      />
      <AreYouSureModal
        description="Are you sure you want to delete this file?"
        onPositiveClicked={() => {
          deleteHandler(selectedRow!);
        }}
        positiveButtonTitle="Delete"
        showModal={showModal}
        title=""
        negativeButtonTitle="Cancel"
        onNegativeClicked={() => setShowModal(false)}
        loading={deleteLoading}
      />
      {loadingContent && (
        <div className="fixed inset-0 bg-black/30 z-50">
          <Spinner />
        </div>
      )}
    </PageContainer>
  );
};

export default FileManager;
