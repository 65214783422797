import React, { useState } from "react";
import Option from "./Option";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import Button, { ButtonType } from "../../../../Button";

const ManageNewsletterButton = () => {
  const navigate = useNavigate();
  const [showMenu, setShowMenue] = useState(false);
  const [boxIsHovered, setBoxIsHovered] = useState(false);
  return (
    <div className="relative">
      <Button
        className="h-10   mr-2 "
        type={ButtonType.PRIMARY}
        onClick={() => setShowMenue(!showMenu)}
        onBlur={() => !boxIsHovered && setShowMenue(false)}
      >
        <FontAwesomeIcon icon={faPlus} className="mr-1" />
        Manage Newsletter Emails
      </Button>
      {showMenu && (
        <ul
          onMouseOver={() => setBoxIsHovered(true)}
          onMouseOut={() => setBoxIsHovered(false)}
          onTouchStart={() => setBoxIsHovered(true)}
          onTouchEnd={() => setBoxIsHovered(false)}
          className="absolute bg-white rounded shadow mt-1 z-50"
        >
          <Option
            label="Unsubscribe"
            onclick={() => {
              navigate("/content/20/blocks/");
            }}
          />
          <Option
            label="Confirm"
            onclick={() => {
              navigate("/content/19/blocks/");
            }}
          />
        </ul>
      )}
    </div>
  );
};

export default ManageNewsletterButton;
