import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button, { ButtonType } from "../Button";
import { faEye, faFloppyDiskCircleXmark } from "@fortawesome/pro-regular-svg-icons";
import { StatusBoxType } from "../../types/types";
import { getStatusTypeStr } from "../../utils/utils";
import { Link, useNavigate } from "react-router-dom";
import { ContentSubmit, determineContentStatus } from "../../api/content";
import { useEffect, useState } from "react";
import UnsavedChangesModal from "./UnsavedChangesModal";
import AreYouSureModal from "./AreYouSureModal";
import Loading, { LoadingMode } from "../Loading";
import CmsModalMainShape from "../CmsModalMainShape";

export enum ContentMode {
  ManagePage,
  ManageBlocks,
}

interface Props {
  headerTitle: string;
  contentId: string;
  contentTitle: string;
  contentType: string;
  hasNewsletterSent?: boolean;
  sendNewsLetter?: boolean;
  contentStatus: StatusBoxType;
  contentMode: ContentMode;
  isAddMode?: boolean;
  blockCount?: number;
  isUsedMessage?: string | null;
  saveBlocksBeforeNavigating?: () => Promise<boolean>;
  contentStatusChanged?: (newStatus: ContentSubmit) => void;
}

/** The pages which don't need BreadCrumb */
const excludePageIds = [
  "2", // Footer
];

export default function ContentSettingsHeader(props: Props) {
  const navigate = useNavigate();
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [showUnpublishModal, setShowUnpublishModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showLoading, setShowLoading] = useState<{
    showPublishLoading: boolean;
    showUnpublishLoading: boolean;
    showDiscardLoading: boolean;
  }>({
    showPublishLoading: false,
    showUnpublishLoading: false,
    showDiscardLoading: false,
  });
  const [showModal, setShowModal] = useState(true);

  const params = useParams();
  const showBreadCrumb = !excludePageIds.includes(props.contentId);

  const handleBreadcrumb = () => {
    return (
      <header>
        <div className="flex breadcrumb-text mt-2">
          <Link to="/content">Content</Link>
          <span className="mx-1">&gt;</span>

          {props.contentType === "Post Additional" ? (
            <span>{props.contentTitle}</span>
          ) : (
            <Link
              to={`/content/${props.contentId}`}
              className={`${props.isAddMode || props.contentMode !== ContentMode.ManageBlocks ? "cursor-default" : ""} ${
                props.contentMode !== ContentMode.ManageBlocks && "breadcrumb-last-text"
              }`}
              onClick={(event) => {
                if (props.isAddMode) {
                  event.preventDefault();
                }
              }}
            >
              {props.contentTitle}
            </Link>
          )}
          {props.contentMode === ContentMode.ManageBlocks && (
            <div className="flex">
              <span className="mx-1 breadcrumb-last-text">&gt;</span>
              <p className="breadcrumb-last-text">Manage Blocks</p>
              <CmsModalMainShape
                onNegativeBtnClick={() => setShowPublishModal(false)}
                onPositiveBtnClick={() => publish()}
                positiveBtnTitle="Publish"
                showModal={showPublishModal}
                title=""
                loading={showLoading.showPublishLoading}
              >
                The newsletter will be sent to all subscribers. Are you sure to publish the Post?
              </CmsModalMainShape>
            </div>
          )}
        </div>
      </header>
    );
  };

  const publish = () => {
    if (props.contentMode === ContentMode.ManageBlocks && props.contentStatus !== StatusBoxType.DP && props?.blockCount === 0) {
      setShowWarningModal(true);
      return;
    }

    setShowLoading({ ...showLoading, showPublishLoading: true });

    determineContentStatus(props.contentId, ContentSubmit.Publish).then(() => {
      props.contentStatusChanged?.(ContentSubmit.Publish);
      setShowLoading({ ...showLoading, showPublishLoading: false });
      setShowPublishModal(false);
    });
  };

  const getFunctionalBtn = () => {
    switch (props.contentStatus) {
      case StatusBoxType.DF:
        return (
          <Button
            className="font-normal"
            showLoading={showLoading.showPublishLoading}
            type={ButtonType.PRIMARY}
            onClick={() => {
              !props.hasNewsletterSent && props.sendNewsLetter && props.contentType === "Post" ? setShowPublishModal(true) : publish();
            }}
          >
            Publish
          </Button>
        );
      case StatusBoxType.PB:
        return (
          <Button
            className="font-normal"
            type={ButtonType.PRIMARY}
            showLoading={showLoading.showUnpublishLoading}
            onClick={() => {
              if (props.isUsedMessage === null) {
                setShowLoading({ ...showLoading, showUnpublishLoading: true });
                determineContentStatus(props.contentId, ContentSubmit.unPublish).then(() => {
                  props.contentStatusChanged?.(ContentSubmit.unPublish);
                  setShowLoading({
                    ...showLoading,
                    showUnpublishLoading: false,
                  });
                });
              } else {
                setShowUnpublishModal(true);
                setShowModal(true);
              }
            }}
          >
            Unpublish
          </Button>
        );
      case StatusBoxType.DP:
        return (
          <div className="flex items-center flex-wrap gap-2">
            <div className="flex items-center justify-center">
              {showLoading.showDiscardLoading && <Loading style={{ marginRight: 8 }} loadingMode={LoadingMode.Button} />}
              <label className="text-primary font-medium cursor-pointer" onClick={() => setShowDiscardModal(true)}>
                Discard Unpublished Changes
              </label>
            </div>
            <Button
              showLoading={showLoading.showUnpublishLoading}
              className="font-normal"
              type={ButtonType.SECONDARY}
              onClick={() => {
                setShowUnpublishModal(true);
                setShowModal(true);
              }}
              style={{ backgroundColor: "transparent" }}
            >
              Unpublish
            </Button>
            <Button
              showLoading={showLoading.showPublishLoading}
              className="font-normal"
              type={ButtonType.PRIMARY}
              onClick={() => publish()}
            >
              Publish
            </Button>
          </div>
        );
    }
  };

  const onPreviewButtonClick = () => navigate(`/content/${params.contentId}/preview`);

  return (
    <div className="w-full px-10 mt-[20px]">
      {showBreadCrumb && handleBreadcrumb()}
      <div className={`flex justify-between items-center ${showBreadCrumb && "mt-4"}`}>
        <h3>{props.headerTitle}</h3>
        {props.contentMode === ContentMode.ManageBlocks && (
          <Button className="font-normal" type={ButtonType.SECONDARY} onClick={onPreviewButtonClick}>
            <div className="flex items-center">
              <FontAwesomeIcon className="mr-2 text-primary cursor-pointer" icon={faEye} />
              <span>Preview</span>
            </div>
          </Button>
        )}
      </div>
      {!props.isAddMode && (
        <div className="info-box">
          <div className="flex flex-wrap items-center justify-between" style={{ gap: "1rem 7rem" }}>
            <div className="flex gap-1">
              <span className="font-medium">Title:</span>
              <span className="font-light">{props.contentTitle}</span>
            </div>
            {props.contentStatus && (
              <div className="flex items-center">
                <span className="font-medium">Published Status:</span>
                <span className="ml-1 font-light">{getStatusTypeStr(props.contentStatus)}</span>
                {props.contentStatus === StatusBoxType.DP && <FontAwesomeIcon icon={faFloppyDiskCircleXmark} className="ml-2" />}
              </div>
            )}
            <div className="flex gap-1">
              <span className="font-medium">Type:</span>
              <span className="font-light">{props.contentType}</span>
            </div>
          </div>
          <div className="flex items-center">
            {props.contentMode === ContentMode.ManageBlocks ? (
              getFunctionalBtn()
            ) : (
              <Button
                className="font-normal"
                type={ButtonType.PRIMARY}
                onClick={() => {
                  if (props.saveBlocksBeforeNavigating) {
                    const result = props.saveBlocksBeforeNavigating?.();
                    result.then((res) => {
                      if (res) {
                        navigate("blocks", {
                          state: { contentType: props.contentType },
                        });
                      }
                    });
                  }
                }}
              >
                Review for Publish
              </Button>
            )}
          </div>
        </div>
      )}

      {showDiscardModal && (
        <UnsavedChangesModal
          contentSubmit={ContentSubmit.Discard}
          showModal={showDiscardModal}
          onPositiveClicked={() => {
            setShowLoading({ ...showLoading, showDiscardLoading: true });

            determineContentStatus(props.contentId, ContentSubmit.Discard).then(() => {
              props.contentStatusChanged?.(ContentSubmit.Discard);
              setShowLoading({ ...showLoading, showDiscardLoading: false });
            });
            setShowDiscardModal(false);
          }}
          onNegativeClicked={() => setShowDiscardModal(false)}
        />
      )}

      {showUnpublishModal && props.isUsedMessage == null && (
        <UnsavedChangesModal
          contentSubmit={ContentSubmit.unPublish}
          showModal={showUnpublishModal}
          onPositiveClicked={() => {
            setShowLoading({ ...showLoading, showUnpublishLoading: true });

            determineContentStatus(props.contentId, ContentSubmit.unPublish).then(() => {
              props.contentStatusChanged?.(ContentSubmit.unPublish);
              setShowLoading({ ...showLoading, showUnpublishLoading: false });
            });
            setShowUnpublishModal(false);
          }}
          onNegativeClicked={() => setShowUnpublishModal(false)}
        />
      )}
      {showUnpublishModal && props.isUsedMessage != null && (
        <AreYouSureModal
          title={""}
          description={props.isUsedMessage}
          positiveButtonTitle={"Cancel"}
          negativeButtonTitle={""}
          showModal={showModal}
          onPositiveClicked={() => {
            setShowModal(false);
          }}
          onNegativeClicked={() => setShowModal(false)}
        />
      )}

      {showWarningModal && (
        <AreYouSureModal
          showModal={showWarningModal}
          title={"Attention!"}
          description={"There is no content to publish, please add some blocks first."}
          positiveButtonTitle={"OK"}
          onPositiveClicked={() => setShowWarningModal(false)}
        />
      )}
    </div>
  );
}
