import { useLocation, useNavigate } from "react-router-dom";
import SearchInput from "../../../inputs/SearchInput";
import Button, { ButtonType } from "../../../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useState } from "react";
import { getAllPostType, updatePostType, createPostType, deletePostType } from "../../../../api/postType";
import AddOrEditValueModal from "../AddOrEditPostTypeModal";
import DeleteDictValueModal, { DeleteModalType } from "../DeletePostTypeModal";
import { Snackbar, SnackbarContent } from "@mui/material";
import PageContainer from "../../../layout/PageContainer";
import Table from "./Table";
import Line from "../../Line";
import ManageNewsletterButton from "../fieldManagement/manageValue/ManageNewsletterButton";

export enum PostType {
  Blog = "Blog",
  Vacancies = "Vacancies",
  News = "News",
}

export enum PostTypeOptionType {
  Edit,
  Delete,
  ManageAdditionalFields,
  ManageCategory,
  AdditionalConent,
}

export type PostTypeType = {
  id: number;
  name: string;
  isActive: boolean;
  hasHeroBlock: boolean;
  badge: string | null;
  site: number;
  showSummaryInPublicHeader: boolean;
  postAdditionalId?: number;
  buttomPostAdditionalId?: number;
  sendNewsletter: boolean;
};

export type PostTypeAdditionalContent = {
  max_width: number;
};

export enum ManagePostTypeType {
  ManageValue = "ManageValue",
  ManagePostCategory = "ManagePostCategory",
}

export default function ManagePostType() {
  const columns = [
    { Header: "Name", accessor: "name" },
    { Header: "Active", accessor: "active" },
    { Header: "Show Header", accessor: "show_summary_in_public_header" },
    { Header: " ", accessor: "" },
  ];

  const navigate = useNavigate();
  const location = useLocation();
  const [allValues, setAllValues] = useState<Array<PostTypeType>>([]);
  const [showingValues, setShowingValues] = useState<Array<PostTypeType>>([]);
  const [searchPhrase, setSearchPhrase] = useState<string | null>(null);
  const [showAddOrEditValueModal, setShowAddOrEditValueModal] = useState<{
    dictValue?: PostTypeType;
    showModal: boolean;
  }>({
    showModal: false,
  });

  const [showSnackbar, setShowSnackbar] = useState<{
    showSnack: boolean;
    isSuccess: boolean;
    message?: string;
  }>({
    showSnack: false,
    isSuccess: false,
  });

  const [showDeleteModal, setShowDeleteModal] = useState<{
    dictValue?: PostTypeType & PostTypeAdditionalContent;
    showModal: boolean;
  }>({
    showModal: false,
  });

  const fetchData = () => {
    getAllPostType().then((res) => {
      setAllValues(res.data.data);
      setShowingValues(res.data.data);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    search();
  }, [searchPhrase]);

  const search = () => {
    if (searchPhrase) {
      const targetData = allValues.filter((dict) => {
        return JSON.stringify(dict.name).toLowerCase().includes(searchPhrase?.toLowerCase());
      });
      setShowingValues(targetData);
    } else {
      setShowingValues(allValues);
    }
  };

  const onOptionClick = (dictValue: PostTypeType & PostTypeAdditionalContent, selectedValue: PostTypeOptionType, id?: number) => {
    switch (selectedValue) {
      case PostTypeOptionType.Edit:
        setShowAddOrEditValueModal({ dictValue: dictValue, showModal: true });
        break;
      case PostTypeOptionType.Delete:
        setShowDeleteModal({ dictValue: dictValue, showModal: true });
        break;
      case PostTypeOptionType.ManageAdditionalFields:
        //Go to field page

        navigate(`${location.pathname}/${dictValue.id}/fields`);
        break;
      case PostTypeOptionType.ManageCategory:
        //Go to field page

        navigate(`${location.pathname}/${dictValue.id}/categories`);
        break;
      case PostTypeOptionType.AdditionalConent:
        //Go to Additional content page

        navigate(`/content/${id}/blocks/`);
        break;
    }
  };

  const operationCompleted = (_isSuccess: boolean, _message: string, id?: number) => {
    if (_isSuccess) {
      // getAllValues();
      fetchData();
    }

    setShowSnackbar({
      showSnack: true,
      isSuccess: _isSuccess,
      message: _message,
    });
    if (id) {
      navigate(`${location.pathname}/${id}/fields`);
    }
  };

  return (
    <PageContainer>
      <div className="w-full">
        <div className="1069:flex justify-between mx-10 py-[44px] ">
          <h3>{"Post Type Management"}</h3>
          <div className="616:flex flex-wrap 888:mt-0 mt-2 justify-end">
            <SearchInput
              searchHandler={(searchedPhrase) => {
                setSearchPhrase(searchedPhrase);
              }}
              className=" mr-4 888:w-[400px]"
            />
            <div className="flex flex-wrap mt-2 690:mt-0">
              <ManageNewsletterButton />
              <Button
                className="h-10 w-[125px] 452:mt-0 mt-2"
                type={ButtonType.PRIMARY}
                onClick={() => setShowAddOrEditValueModal({ showModal: true })}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-1" />
                Add Type
              </Button>
            </div>
          </div>
        </div>

        <Line />

        {showingValues?.length > 0 ? (
          <div className="mx-12 mt-6">
            <Table columns={columns} data={showingValues} onOptionClick={onOptionClick} />
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center">
            <img className="self-center mt-16" src={require("../../../../images/no_data_img.png")} width={572} height={406} />
            <label className="font-base text-lg">{"No posts are added"}</label>
          </div>
        )}

        {showAddOrEditValueModal.showModal && (
          <AddOrEditValueModal
            postTypeValue={showAddOrEditValueModal.dictValue}
            onClose={() =>
              setShowAddOrEditValueModal({
                dictValue: undefined,
                showModal: false,
              })
            }
            onSave={(postTypeValue: PostTypeType & PostTypeAdditionalContent) => {
             
              if (postTypeValue.id === -1) {
                createPostType(postTypeValue).then((res) => {
                  operationCompleted(res.status === 201, "Post Type created successfully", res.data.id);
                });
              } else {
                updatePostType(postTypeValue).then((res) => operationCompleted(res.status === 200, "Post Type Updated successfully"));
              }

              setShowAddOrEditValueModal({
                dictValue: undefined,
                showModal: false,
              });
            }}
          />
        )}

        {showDeleteModal.showModal && (
          <DeleteDictValueModal
            postType={showDeleteModal.dictValue!}
            onPositiveClicked={(modalType) => {
              if (modalType === DeleteModalType.Delete) {
                deletePostType(showDeleteModal.dictValue!.id).then((res) => {
                  operationCompleted(res.status === 204, "Dictionary value removed");
                });
              } else {
                // Just inactivate it
                updatePostType(showDeleteModal.dictValue!, true).then((res) =>
                  operationCompleted(res.status === 200, `${showDeleteModal.dictValue} deactivated`)
                );
              }

              setShowDeleteModal({ dictValue: undefined, showModal: false });
            }}
            onNegativeClicked={() => setShowDeleteModal({ dictValue: undefined, showModal: false })}
          />
        )}

        <Snackbar
          open={showSnackbar.showSnack}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          sx={{
            "& .MuiSnackbarContent-root": {
              backgroundColor: `${showSnackbar.isSuccess ? "#03A678" : "#D11C1C"} `,
            },
            "& .MuiSnackbarContent-message": {
              fontSize: "16px",
              fontFamily: "'Open Sans', sans-serif",
              fontWeight: "100",
            },
          }}
          onClose={() => {
            setShowSnackbar({ ...showSnackbar, showSnack: false });
          }}
        >
          <SnackbarContent message={<p>{showSnackbar.message}</p>} />
        </Snackbar>
      </div>
    </PageContainer>
  );
}
