import React, { useEffect, useState } from "react";
import { AxiosProgressEvent } from "axios";
import Button, { ButtonType } from "../../Button";
import { editFiles } from "../../../api/fileManager";
import FileUploader from "./FileUploader";
import { FileDataType } from "../../../types/types";
import LanguageSelector from "../../inputs/LanguageSelector";

const UploadBox: React.FC<{
  id: string;
  respHandler: (resp: FileDataType) => void;
  data: FileDataType;
}> = ({ id, respHandler, data }) => {
  const [loading, setLoading] = useState(false);
  const [alternativeTextState, setAlternativeTextState] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [showPercent, setShowPercent] = useState(false);
  const [cancelClicked, setCancelClicked] = useState(false);
  const [cancelStatus, setCancelStatus] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [alternativeLanguages, setAlternativeLanguages] = useState({});

  const formData = new FormData();

  const addLanguage = (altText: string) => {
    setAlternativeLanguages((prevObj) => ({ ...prevObj, [selectedLanguage]: altText }));
  };

  useEffect(() => {
    // @ts-ignore
    alternativeLanguages[selectedLanguage] === "undefined" || alternativeLanguages[selectedLanguage] === null
      ? setAlternativeTextState("")
      : // @ts-ignore
        setAlternativeTextState(alternativeLanguages[selectedLanguage]);
  }, [alternativeLanguages, selectedLanguage]);
  useEffect(() => {
    setAlternativeLanguages(data.alternative);
    setSelectedLanguage(data.defaultLanguage);
  }, [data]);

  return (
    <div className="max-w-[594px] 1304:mr-6 1304:ml-6 flex-1 mx-auto">
      <p className="pt-[68px]">File Uploader</p>
      <div className="bg-white shadow">
        <div className="mt-2 px-6">
          <FileUploader
            cancelStatus={cancelStatus}
            uploadProgress={uploadProgress}
            data={data}
            onChange={(file) => {
              setSelectedFile(file);
              setCancelClicked(false);
            }}
            showPercent={showPercent}
          />
        </div>
        <div className="px-6">
          <div className="mt-2 max-w-[522px] mx-auto">
            <LanguageSelector languages={data.languages} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />

            <label htmlFor="alternativeText" className="block">
              Alt
            </label>
            <input
              id="alternativeText"
              className="rounded border items-center border-secondary-300 hover:border-primary font-normal outline-none h-[40px] w-full px-4"
              type="text"
              placeholder={`alternativeText ( ${selectedLanguage.toUpperCase()} )`}
              // @ts-ignore
              value={alternativeTextState ? alternativeTextState : ""}
              onChange={(e) => {
                setAlternativeTextState(e.target.value);
                addLanguage(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="flex flex-row-reverse mt-16 pr-8 pb-6">
          <Button
            showLoading={loading}
            onClick={() => {
              formData.append("alternative", JSON.stringify(alternativeLanguages));
              !cancelClicked && selectedFile && formData.append("file", selectedFile as Blob);
              setLoading(true);
              setShowPercent(true);
              editFiles(id, formData, (progressEvent: AxiosProgressEvent) => {
                if (progressEvent.total) {
                  const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                  setUploadProgress(percentCompleted);
                }
              }).then((resp) => {
                if (resp.status === 200) {
                  respHandler({
                    ...resp.data,
                    fileName: resp.data.name,
                    isUsed: resp.data.numberOfUsage > 0 ? "true" : "false",
                    languages: resp.data.languages,
                    defaultLanguage: selectedLanguage,
                  });
                  setLoading(false);
                  setShowPercent(false);
                }
              });
            }}
            type={ButtonType.PRIMARY}
          >
            Save
          </Button>
          <Button
            className="mr-4 "
            onClick={() => {
              // @ts-ignore
              setAlternativeTextState(data.alternative[selectedLanguage]);
              setAlternativeLanguages(data.alternative);
              setCancelClicked(true);
              setCancelStatus(!cancelStatus);
            }}
            type={ButtonType.SECONDARY}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UploadBox;
