import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faClose } from "@fortawesome/pro-regular-svg-icons";
const SearchInput: React.FC<{
  searchHandler?: (input: string) => void;
  onChange?: (input: string) => void;
  className?: string;
  rstInt?: boolean;
}> = ({ searchHandler, onChange, className, rstInt }) => {
  const [searchValue, setSearchValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  useEffect(() => {
    setSearchValue("");
  }, [rstInt]);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      searchHandler && searchHandler(searchValue);
    }
  };
  return (
    <div
      tabIndex={0}
      onBlur={() => setIsFocused(false)}
      onFocus={() => setIsFocused(true)}
      className={` h-[40px] rounded border items-center border-secondary-300 hover:border-primary font-normal relative ${className}`}
    >
      <FontAwesomeIcon
        onClick={() => {
          setSearchValue("");
          searchHandler && searchHandler("");
          onChange && onChange("");
        }}
        icon={faClose}
        className={`text-sm text-error hover:cursor-pointer absolute top-1/2 -translate-y-1/2 right-4 ${!searchValue && "hidden"}`}
      />
      {!searchValue && (
        <div>
          <FontAwesomeIcon
            onClick={() => {
              searchHandler && searchHandler(searchValue);
            }}
            icon={faMagnifyingGlass}
            className={`text-sm duration-100 text-secondary-200 hover:cursor-pointer absolute top-1/2 -translate-y-1/2 ${
              isFocused ? "right-4" : "left-4"
            }`}
          />
        </div>
      )}
      <div className="w-full mr-4">
        <input
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
            onChange && onChange(e.target.value);
          }}
          onKeyDown={(e) => handleKeyDown(e)}
          placeholder={`${isFocused ? "" : "Search"}`}
          className={`inline-block h-[38px] w-full outline-0 outline-none ${isFocused ? "pl-6" : "pl-8"} rounded placeholder-[#D2CEC6]`}
        />
      </div>
    </div>
  );
};

export default SearchInput;
