import React, { useState } from "react";
import SortAndDisplayField from "./SortAndDisplayField";
import Table, { Pagination, SortItemType } from "./table/Table";
import { FileContentType } from "../../types/types";

const FileManagerContent: React.FC<{
  visibile: boolean;
  data: Array<FileContentType>;
  deleteHandler: (id: number) => void;
  pagination: Pagination;
  setPageSize: (input: number) => void;
  pageSize: number;
  gotoPageHandler: (input: number) => void;
  sortHandler: (input: SortItemType) => void;
}> = ({ visibile, data, deleteHandler, pagination, setPageSize, pageSize, gotoPageHandler, sortHandler }) => {
  const columns = [
    { Header: "Name", accessor: "name" },
    { Header: "Type", accessor: "type" },
    { Header: "Size", accessor: "size" },
    { Header: "Used", accessor: "isUsed" },
    { Header: "Upload Date", accessor: "uploadDate" },
    { Header: " ", accessor: "" },
  ];

  const [listOrGridstate, setListOrGridstate] = useState(false);
  const [sortStatus, setSortStatus] = useState<SortItemType>({ name: "", status: 0 });

  return (
    <div className={`${visibile ? "block" : "hidden"}`}>
      <div className="pt-6 px-10">
        <SortAndDisplayField
          setListOrGridstate={(state) => {
            setListOrGridstate(state);
          }}
          selectedSortedAs={(input) => {
            setSortStatus(input);
            sortHandler(input);
          }}
          sortedAs={sortStatus}
        />
      </div>
      {data.length === 0 ? (
        <div className=" h-[300px] flex items-center justify-center">{"no_result"}</div>
      ) : (
        <div className="pt-6 px-10 bg-white">
          <Table
            sortHandler={sortHandler}
            columns={columns}
            data={data}
            listOrGridstate={listOrGridstate}
            deleteHandler={deleteHandler}
            pagination={pagination}
            setPageTotalSize={setPageSize}
            pageSize={pageSize}
            gotoPageHandler={gotoPageHandler}
            setSortStatus={setSortStatus}
            sortStatus={sortStatus}
          />
        </div>
      )}
    </div>
  );
};

export default FileManagerContent;
