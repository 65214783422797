import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faMultiply } from "@fortawesome/pro-solid-svg-icons";

const IsUsedBox: React.FC<{ numberOfUsage: number }> = ({ numberOfUsage }) => {
  let isUsedBox: JSX.Element;

  if (numberOfUsage === 0) {
    isUsedBox = (
      <span className="w-[22px] h-[22px] border border-[#E55D63] rounded-full text-[#E55D63] flex items-center justify-center">
        <FontAwesomeIcon className="text-base" icon={faMultiply} />
      </span>
    );
    return <div>{isUsedBox}</div>;
  } else {
    isUsedBox = (
      <span className="w-[22px] h-[22px] flex border border-[#62A630] rounded-full text-[#62A630] items-center justify-center">
        <FontAwesomeIcon className="text-base" icon={faCheck} />
      </span>
    );
    return <div>{isUsedBox}</div>;
  }
};

export default IsUsedBox;
