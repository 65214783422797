import React from "react";
import { Row } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FileContentType } from "../../../types/types";
import TypeBox from "./row/TypeBox";
import { characterSpliter } from "../../../utils/utils";
import Moment from "react-moment";
import { Link } from "react-router-dom";

const TableGrid: React.FC<{ row: Row<FileContentType>; deleteHandler: (id: number) => void }> = ({ row, deleteHandler }) => {
  return (
    <tr
      className=" h-[146px] w-[318px] border-[1px] border-[#F0EFED]  rounded  shadow-md relative"
      style={{
        background: `linear-gradient(rgba(251, 251, 250, 0.9), rgba(251, 251, 250, 0.9)), url(${row.original.url})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <td>
        <Link to={`${row.original.id}`} className="text-[#838281] absolute inset-0 " />
      </td>
      {row.original.numberOfUsage !== 0 ? (
        <td className="absolute right-0 bg-green px-4 py-1 text-white font-normal text-sm rounded-l top-[10px]">In Used</td>
      ) : (
        <td onClick={() => deleteHandler(row.original.id)} className="absolute right-[19px] top-[23px] z-10">
          <FontAwesomeIcon className="text-base text-error mx-[5px] cursor-pointer" icon={faTrash} />
        </td>
      )}

      <td className=" text-sm font-normal mt-6 ml-6 inline-block">{characterSpliter(row.original.name, 20)}</td>
      <td>
        <TypeBox type={row.original.type} className="mt-6 ml-[22px]" />
      </td>
      <td className="flex justify-between text-[#848484] px-6 mt-3">
        <div className="text-xs font-normal ">{row.original.size}</div>
        <div className="text-sm font-normal">
          <Moment format="MM.DD.YYYY">{row.original.uploadDate}</Moment>
        </div>
      </td>
    </tr>
  );
};

export default TableGrid;
