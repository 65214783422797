import React, { useEffect, useState } from "react";
import InputField from "../inputs/InputField";
import { SiteSettingType } from "../../types/types";
import LanguageSelector from "../inputs/LanguageSelector";

const AnalysisBox: React.FC<{ dataState: SiteSettingType; setDataState: (data: SiteSettingType) => void }> = ({
  dataState,
  setDataState,
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState("");
  useEffect(() => {
    setSelectedLanguage(dataState.defaultLanguage);
  }, [dataState.defaultLanguage]);

  return (
    <div className="bg-secondary-100 relative w-full 1140:w-1/2  1140:my-0 my-2 1140:ml-2 ">
      <div className="p-4">
        <div>Anylysis</div>
        <LanguageSelector
          languages={dataState.languages}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          className="mt-8"
        />
        <div className="mt-4 max-w-[238px]">
          <InputField
            label="Site Name"
            onChange={(value) => {
              setDataState({ ...dataState, siteName: { ...dataState.siteName, [selectedLanguage]: value } });
            }}
            type="T"
            value={dataState.siteName[selectedLanguage] ?? ""}
          />
        </div>
        <div className="mt-4 max-w-[238px]">
          <InputField
            label="Logo Redirect Link"
            onChange={(value) => {
              setDataState({ ...dataState, logoRedirectLink: { ...dataState.logoRedirectLink, [selectedLanguage]: value } });
            }}
            type="T"
            value={dataState.logoRedirectLink[selectedLanguage] ?? ""}
          />
        </div>
      </div>

      <div className="bg-white w-full top-0 left-0 h-1 mt-8"></div>
      <div className="p-4">
        <div className="mt-4 max-w-[238px]">
          <InputField
            label="Google Tag"
            onChange={(value) => {
              setDataState({ ...dataState, gtTrackingId: value });
            }}
            type="T"
            value={dataState.gtTrackingId ?? ""}
          />
        </div>
        <div className="mt-4 max-w-[238px]">
          <InputField
            label="Google Site Verification"
            onChange={(value) => {
              setDataState({ ...dataState, gVerification: value });
            }}
            type="T"
            value={dataState.gVerification ?? ""}
          />
        </div>
      </div>
    </div>
  );
};

export default AnalysisBox;
