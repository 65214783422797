import React from "react";

const LanguageSelector: React.FC<{
  languages: string[];
  setSelectedLanguage: (i: string) => void;
  selectedLanguage: string;
  className?: string;
}> = ({ languages, setSelectedLanguage, selectedLanguage, className }) => {
  return (
    <div className={`flex ${className ? className : ""}`}>
      {languages &&
        languages.map((lang, i) => (
          <div
            key={i}
            onClick={() => {
              setSelectedLanguage(lang);
            }}
            className={`capitalize flex w-[40px] h-[40px] items-center justify-center cursor-pointer first:rounded-l first:border-l last:rounded-r border-r-[1px] border-[#C4C4C4] border-t border-b  ${
              selectedLanguage === lang ? "bg-primary text-white" : "text-black bg-white"
            }`}
          >
            {lang}
          </div>
        ))}
    </div>
  );
};

export default LanguageSelector;
