import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangle, faBars } from "@fortawesome/pro-solid-svg-icons";
import React, { useEffect, useState } from "react";
import LinksMenu from "./LinksMenu";
import { logoSelector, redirectToLogin } from "../../../utils/utils";

const Header: React.FC<{ showContentChildren: () => void }> = ({ showContentChildren }) => {
  const location = useLocation();

  const [showMenue, setShowMenue] = useState(false);
  const [showLinksMenu, setShowLinksMenu] = useState(false);
  const [boxIsHovered, setBoxIsHovered] = useState(false);
  const [clickedItem, setClickedItem] = useState("");
  const [imageSrc, setImageSrc] = useState("");

  const links = [
    {
      linkName: "Content",
      linkAddress: "",
      hasBorder: location.pathname.includes("/menu") || location.pathname.includes("/content"),
      subMenu: [
        {
          linkName: "Content",
          linkAddress: "/content",
        },
        {
          linkName: "Menu",
          linkAddress: "/menu",
        },
        {
          linkName: "Footer",
          linkAddress: "/content/2/blocks",
        },
      ],
    },
    {
      linkName: "File Manager",
      linkAddress: "/file-manager",
      hasBorder: location.pathname.includes("/file-manager"),
      subMenu: [],
    },
    {
      linkName: "History",
      linkAddress: "/history",
      hasBorder: location.pathname.includes("/history"),
      subMenu: [],
    },
    {
      linkName: "Site Theme",
      linkAddress: "/site-theme",
      hasBorder: location.pathname.includes("/site-theme"),
      subMenu: [],
    },
    {
      linkName: "Site Setting",
      linkAddress: "",
      hasBorder:
        location.pathname.includes("/site-setting") ||
        location.pathname.includes("/language") ||
        location.pathname.includes("/post-category") ||
        location.pathname.includes("/data-dictionary") ||
        location.pathname.includes("post-type") ||
        location.pathname.includes("/cookie"),
      subMenu: [
        { linkName: "General", linkAddress: "/site-setting" },
        { linkName: "Language", linkAddress: "/language" },
        { linkName: "Post Type Mgmt", linkAddress: "/post-type" },
        { linkName: "Cookie", linkAddress: "/cookie" },
        { linkName: "Data Dictionary Hub", linkAddress: "/data-dictionary" },
      ],
    },
  ];

  const listHandler = () => {
    setShowLinksMenu(false);
    showContentChildren();
  };

  useEffect(() => {
    const loginTime = new Date(localStorage.getItem("loginTime")!);
    const currentTime = new Date();
    const differenceInTime = currentTime.getTime() - loginTime.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    if (differenceInDays > 54) {
      redirectToLogin();
    }
  }, []);
  useEffect(() => {
    const documentsContainer = document.getElementById("headerContainer");
    new ResizeObserver((entries) => {
      const width = entries[0].contentRect.width;
      width > 1120 && setShowLinksMenu(false);
    }).observe(documentsContainer!);
  }, []);

  useEffect(() => {
    setImageSrc(logoSelector(location));
  }, []);

  return (
    <div id="headerContainer" className="relative h-full bg-secondary-400">
      <div className="w-full h-full  flex items-center justify-between max-w-[1550px] mx-auto">
        <div className="1120:pl-[72px] pl-6">
          <img src={imageSrc} alt="logo"></img>
        </div>

        <div className=" 1120:block hidden ">
          <nav className="relative">
            <ul className="text-base font-light text-black2B flex relative">
              {links.map((link, i) => (
                <li
                  onClick={() => {
                    clickedItem === "" ? setClickedItem(link.linkName) : setClickedItem("");
                  }}
                  onBlur={() => {
                    !boxIsHovered && setClickedItem("");
                  }}
                  tabIndex={0}
                  key={i}
                  className={`ml-[50px] ${link.hasBorder && "border-b-primary border-b-2 pb-1 "} last:mr-[50px]`}
                >
                  {link.subMenu.length === 0 ? (
                    <Link className="hover:text-primary" to={link.linkAddress}>
                      {link.linkName}
                    </Link>
                  ) : (
                    <div className="cursor-pointer hover:text-primary">
                      {link.linkName}
                      <FontAwesomeIcon
                        onClick={() => setShowMenue(!showMenue)}
                        icon={faTriangle}
                        className={`text-xs ml-2 border-b-0 cursor-pointer duration-200 ${
                          !(link.subMenu.length !== 0 && clickedItem === link.linkName) && "rotate-180"
                        }`}
                      />
                    </div>
                  )}

                  {link.subMenu.length !== 0 && clickedItem === link.linkName && (
                    <div
                      onMouseOver={() => setBoxIsHovered(true)}
                      onMouseOut={() => setBoxIsHovered(false)}
                      onTouchStart={() => setBoxIsHovered(true)}
                      onTouchEnd={() => setBoxIsHovered(false)}
                      className="absolute shadow bg-white rounded top-8"
                    >
                      <ul>
                        {link.subMenu.map((item, index) => (
                          <li key={index}>
                            <Link className="inline-block w-full px-3 py-2 text-xs font-normal" to={item.linkAddress}>
                              {item.linkName}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className="flex">
          <div onClick={() => redirectToLogin()} className="mr-8 cursor-pointer">
            Logout
          </div>
          <div className="block 1120:hidden pr-8">
            <FontAwesomeIcon
              onClick={() => {
                setShowLinksMenu(!showLinksMenu);
                showContentChildren();
              }}
              icon={faBars}
              className="text-base ml-2 rotate-180 border-b-0 hover:cursor-pointer"
            />
          </div>
        </div>
      </div>
      <LinksMenu showMenu={showLinksMenu} links={links} listClicked={listHandler} />
    </div>
  );
};

export default Header;
