import React, { useEffect, useState } from "react";
import { OptionType } from "../../utils/types";
import { FileType } from "../../types/types";
import Button, { ButtonType } from "../Button";
import NumberInput from "../inputs/NumberInput";
import SearchInput from "../inputs/SearchInput";
import DateInput from "../inputs/DateInput";
import SimpleDropDown from "../inputs/SimpleDropDown";

export type ApplyFilterType = {
  type: OptionType | null;
  timeForm: Date;
  timeTo: Date;
  sizeFrom: string;
  sizeTo: string;
  inUsed: OptionType | null;
  searchInput: string;
};
const FilterBox: React.FC<{
  applyButtonHandler: (input: ApplyFilterType) => void;
  searchHandler: (input: string) => void;
  loading: boolean;
}> = ({ applyButtonHandler, loading, searchHandler }) => {
  const [typeOption, setTypeOption] = useState<OptionType | null>(null);
  const [resetIsEnable, setResetIsEnable] = useState(true);
  const [timeFrom, setTimeFrom] = useState<Date>(new Date(0));
  const [timeTo, setTimeTo] = useState(new Date(8640000000000000));
  const [resetLoading, setResetLoading] = useState(false);
  const [applyLoading, setApplyLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [sizeFrom, setSizeFrom] = useState("");
  const [sizeTo, setSizeTo] = useState("");
  const [inUseValue, setInUseValue] = useState<OptionType | null>(null);
  const [rstInt, setRstInt] = useState(false);

  const typeOptions: OptionType[] = [
    { label: "All", key: FileType.AL.toString() },
    { label: "PNG", key: FileType.PNG.toString() },
    { label: "JPG", key: FileType.JPG.toString() },
    { label: "PDF", key: FileType.PDF.toString() },
    { label: "DOCS", key: FileType.DOCS.toString() },
    { label: "SVG", key: FileType.SVG.toString() },
    { label: "JPEG", key: FileType.JPEG.toString() },
    { label: "MP4", key: FileType.MP4.toString() },
  ];

  const isUsedOptions: OptionType[] = [
    { label: "All", key: "all" },
    { label: "In Use", key: "true" },
    { label: "Not Used", key: "false" },
  ];

  const maxDate = new Date(8640000000000000);
  const minDate = new Date(0);
  const resetHandler = () => {
    setInUseValue(null);
    setResetLoading(true);
    setTimeFrom(minDate);
    setTimeTo(maxDate);
    setResetIsEnable(!resetIsEnable);
    setTypeOption(null);
    setSizeFrom("");
    setSizeTo("");
    applyButtonHandler({
      timeForm: minDate,
      timeTo: maxDate,
      type: { key: FileType.AL.toString(), label: "All" },
      inUsed: null,
      sizeFrom: "",
      sizeTo: "",
      searchInput: "",
    });
  };

  useEffect(() => {
    resetLoading && !loading && setResetLoading(false);
  }, [loading, resetLoading]);

  useEffect(() => {
    applyLoading && !loading && setApplyLoading(false);
  }, [loading, applyLoading]);

  return (
    <div className="mx-12 my-6 bg-secondary-100 font-medium text-base py-3 shadow-sm">
      <div className="px-6">
        <SearchInput
          //  searchHandler={searchHandler}
          onChange={(input) => setSearchInput(input)}
          className="my-6 md:w-[400px]"
          rstInt={rstInt}
        />
      </div>
      <div className="h-[1px] w-full bg-secondary-400" />
      <div className="1056:flex flex-wrap px-6 mt-6">
        <div>
          <div className="1230:flex">
            <label className="text-base font-light w-[34px]">Type</label>

            <SimpleDropDown
              onChange={(option) => setTypeOption(option)}
              options={typeOptions}
              selectedOption={typeOption}
              className="w-[217px] 1230:ml-[74px]"
              defaultOption="All"
            />
          </div>
          <div className="1230:flex mt-4">
            <label className="text-base font-light w-[92px]">Upload Date</label>
            <div className="616:flex 1230:mt-0 mt-2">
              <DateInput
                resetIsEnable={resetIsEnable}
                inputValidation={() => {}}
                className="h-[43px] w-[217px] 1230:ml-4"
                placeHolder="From"
                onDateChanged={(date) => {
                  setTimeFrom(date);
                }}
              />
              <DateInput
                resetIsEnable={resetIsEnable}
                className="616:ml-4 w-[217px] h-[43px] 616:mt-0 mt-2 "
                inputValidation={() => {}}
                placeHolder="To"
                onDateChanged={(date) => {
                  setTimeTo(date);
                }}
              />
            </div>
          </div>
        </div>

        <div className="1325:ml-[40px]  1056:mt-0 mt-2 flex flex-col justify-between">
          <div className="1230:flex ">
            <label className="text-base font-light w-[46px]">In Use</label>

            <SimpleDropDown
              className="w-[217px] 1230:ml-2 1056:ml-3"
              options={isUsedOptions}
              onChange={(option) => {
                setInUseValue(option);
              }}
              selectedOption={inUseValue}
              defaultOption="All"
            />
          </div>

          <div className="1230:flex 1056:pl-3 pl-0">
            <label className="text-base font-light flex items-center">size</label>
            <div className=" 616:flex">
              <NumberInput
                className="1230:ml-4 w-[217px]"
                internalLable="From"
                onChange={(e) => setSizeFrom(e)}
                defaultValue={sizeFrom.toString()}
                id="from"
              />
              <NumberInput
                className="616:ml-4 w-[217px] mt-2 616:mt-0"
                internalLable="To"
                onChange={(e) => setSizeTo(e)}
                defaultValue={sizeTo.toString()}
                id="to"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-1 justify-end items-end 960:mt-0 mt-2">
          <div>
            <Button
              className="px-[12px] mr-4"
              onClick={() => {
                resetHandler();
                setRstInt(!rstInt);
              }}
              type={ButtonType.SECONDARY}
              showLoading={resetLoading}
            >
              Reset
            </Button>
          </div>
          <div className="1394:mt-0 mt-4">
            <Button
              className="px-[12px]"
              type={ButtonType.PRIMARY}
              onClick={() => {
                setApplyLoading(true);
                applyButtonHandler({
                  timeForm: timeFrom,
                  timeTo: timeTo,
                  type: typeOption,
                  sizeFrom: sizeFrom,
                  sizeTo: sizeTo,
                  inUsed: inUseValue,
                  searchInput: searchInput,
                });
              }}
              showLoading={applyLoading}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterBox;
