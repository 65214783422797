import React, { useEffect, useRef, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/pro-solid-svg-icons";
import TableRow from "./row/TableRow";
import TableGrid from "./TableGrid";
import { FileContentType } from "../../../types/types";
import Pagination from "./Pagination";

export type Column = {
  Header: string;
  accessor: any;
};
export type SortItemType = { name: string; status: number };
export type Pagination = { totalLenght: number };
//////////////////////////////////////////////
const Table: React.FC<{
  columns: Column[];
  data: Array<FileContentType>;
  listOrGridstate: boolean;
  deleteHandler: (id: number) => void;
  sortHandler: (input: SortItemType) => void;
  pagination: Pagination;
  setPageTotalSize: (input: number) => void;
  gotoPageHandler: (input: number) => void;
  pageSize: number;
  setSortStatus: (status: SortItemType) => void;
  sortStatus: SortItemType;
}> = ({
  columns,
  data,
  listOrGridstate,
  deleteHandler,
  sortHandler,
  pagination,
  setPageTotalSize,
  pageSize,
  gotoPageHandler,
  setSortStatus,
  sortStatus,
}) => {
  const [numberOfColumn, setNumberOfColumn] = useState(0);
  const [selectedNumber, setSelectedNumber] = useState(1);

  const ref = useRef(sortStatus.name);

  // Use the state and functions returned from useTable to build your UI

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // @ts-ignore
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    previousPage,
    // @ts-ignore
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      autoResetPage: false,
    },

    useSortBy,
    usePagination
  );

  useEffect(() => {
    page.length === 0 && previousPage();
  }, [page]);

  useEffect(() => {
    const documentsContainer = document.getElementById("table-container");
    new ResizeObserver((entries) => {
      const width = entries[0].contentRect.width;
      if (width < 1379) {
        if (width < 1050) {
          if (width > 729) {
            setNumberOfColumn(2);
          } else {
            setNumberOfColumn(1);
          }
        } else {
          setNumberOfColumn(3);
        }
      } else {
        setNumberOfColumn(4);
      }
    }).observe(documentsContainer!);
  }, []);

  const selectPage = (_pageNum: number) => {
    gotoPage(_pageNum - 1);
  };

  // Render the UI for your table

  useEffect(() => {
    sortHandler(sortStatus);
  }, [sortStatus]);

  return (
    <div
      id="table-container"
      className={`flex flex-col justify-between min-h-[360px] bg-white ${!listOrGridstate ? "px-2 justify-end" : ""}`}
    >
      {/* table */}
      <div className="relative">
        <div className="w-full mx-auto overflow-auto bg-white scroll-firefox overflow-x-auto overflow-y-hidden">
          <table {...getTableProps()} className="w-full">
            {!listOrGridstate && (
              <thead className="bg-[#F0EFED] rounded inline-block py-2 px-8 w-full">
                {headerGroups.map((headerGroup) => (
                  <tr
                    key={headerGroup.getHeaderGroupProps().key}
                    style={headerGroup.getHeaderGroupProps().style}
                    role={headerGroup.getHeaderGroupProps().role}
                    className="w-full flex justify-between"
                  >
                    {headerGroup.headers.map((column, i) => (
                      <th
                        // @ts-ignore
                        key={column.getHeaderProps(column.getSortByToggleProps()).key}
                        className={`text-sm font-normal text-[#838281] ${i === 0 ? "w-[40%]" : ""}`}
                        onClick={() => {
                          ref.current = column.id;
                          if (sortStatus.status === 2 && ref.current === sortStatus.name) {
                            setSortStatus({ name: column.id, status: 0 });
                          } else {
                            ref.current !== sortStatus.name
                              ? setSortStatus({ name: column.id, status: 1 })
                              : setSortStatus({ name: column.id, status: sortStatus.status + 1 });
                          }
                        }}
                      >
                        {column.render("Header") !== " " && (
                          <div onClick={() => selectPage(1)} className="flex items-center">
                            <h3 className="text-sm font-normal text-[#838281]">{column.render("Header")}</h3>
                            <div className="relative ml-4">
                              <FontAwesomeIcon
                                className={`text-sm absolute top-1 ${
                                  sortStatus.name === column.id && sortStatus.status === 2 ? "text-[#B29B66]" : "text-[#DBD6CB]"
                                }`}
                                icon={faSortDown}
                              />

                              <FontAwesomeIcon
                                className={`text-sm rotate-180 ${
                                  sortStatus.name === column.id && sortStatus.status === 1 ? "text-[#B29B66]" : "text-[#DBD6CB]"
                                }`}
                                icon={faSortDown}
                              />
                            </div>
                          </div>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
            )}
            <tbody
              {...getTableBodyProps()}
              style={
                listOrGridstate
                  ? {
                      display: "grid",
                      justifyContent: "center",
                      gridTemplateColumns: `repeat(${numberOfColumn}, minmax(0,${318}px))`,
                      gap: "24px",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                    }
                  : {}
              }
            >
              {
                // @ts-ignore
                page.map((row, index) => {
                  prepareRow(row);
                  return listOrGridstate ? (
                    <TableGrid row={row} key={row.id} deleteHandler={deleteHandler} />
                  ) : (
                    <TableRow row={row} key={row.id} index={index} numberOfRows={page.length} deleteHandler={deleteHandler} />
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
      <div className="mb-4">
        {pageSize && pagination.totalLenght && (
          <Pagination
            previousPage={() => {
              gotoPageHandler(selectedNumber - 1);
              setSelectedNumber(selectedNumber - 1);
            }}
            nextPage={() => {
              gotoPageHandler(selectedNumber + 1);
              setSelectedNumber(selectedNumber + 1);
            }}
            canPreviousPage={selectedNumber > 1}
            canNextPage={(selectedNumber - 1) * pageSize + page.length < pagination.totalLenght}
            setPageSize={(input) => {
              setPageSize(input);
              setPageTotalSize(input);
            }}
            gotoPage={(i) => {
              // @ts-ignore
              gotoPageHandler(i + 1);
              // @ts-ignore
              setSelectedNumber(i + 1);
            }}
            pageCount={Math.ceil(pagination.totalLenght / pageSize)}
            // @ts-ignore
            pageIndex={selectedNumber - 1}
            pageSize={pageSize}
            pageLength={page.length}
            totalLength={pagination.totalLenght}
            pageName="FileManager"
            numberOfItemsArray={["4", "8", "16"]}
            selectedNumber="4"
          />
        )}
      </div>
    </div>
  );
};

export default Table;
