import { useEffect, useState } from "react";
import CmsModalMainShape from "../../CmsModalMainShape";
import InputField from "../../inputs/InputField";
import { ToggleSwitch } from "../../layout/BlockDetailsSectionContainer";
import { PostTypeType, PostTypeAdditionalContent } from "./manageValue/ManagePostType";

import { getPostType } from "../../../api/postType";

interface Props {
  postTypeValue?: PostTypeType;
  onClose: () => void;
  onSave: (dictValue: PostTypeType & PostTypeAdditionalContent) => void;
}

export default function AddOrEditValueModal(props: Props) {
  const PostTypeDefault: PostTypeType & PostTypeAdditionalContent = {
    id: -1,
    isActive: true,
    badge: null,
    site: 1,
    showSummaryInPublicHeader: false,
    name: "",
    max_width: 10,
    hasHeroBlock: false,
    sendNewsletter: false,
  };

  const [postTypeValue, setPostTypeValue] = useState<PostTypeType & PostTypeAdditionalContent>(PostTypeDefault as any);

  const [validationError, setValidationError] = useState<string | null>(null);

  const valueIsValid = () => {
    if (!postTypeValue.name) {
      setValidationError(`Please set name `);
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (props?.postTypeValue?.id) {
      getPostType(`${props?.postTypeValue?.id}`).then((res) => {
        setPostTypeValue({
          ...res.data.PostType,
          separateScroll: res?.data?.PostAdditional?.separateScroll,
          max_width: res?.data?.PostAdditional?.maxWidth,
        });
      });
    }
  }, [props?.postTypeValue]);

  return (
    <CmsModalMainShape
      title={"Add/Edit Type"}
      positiveBtnTitle={"Save"}
      showModal={true}
      onNegativeBtnClick={props.onClose}
      onPositiveBtnClick={() => {
        if (valueIsValid()) props.onSave(postTypeValue);
      }}
      rootStyle={{ width: 490 }}
    >
      <div className="flex items-start flex-col">
        <div className="flex w-full mt-6 ">
          <div className="flex items-center">
            <label className="font-light text-black2B">Active</label>
            <ToggleSwitch
              className="ml-4"
              checked={postTypeValue.isActive}
              onClick={() =>
                setPostTypeValue({
                  ...postTypeValue,
                  isActive: !postTypeValue.isActive,
                })
              }
            />
          </div>
          <div className="flex items-center ml-[87px]">
            <label className="font-light text-black2B">Show Header</label>
            <ToggleSwitch
              className="ml-4"
              checked={postTypeValue.showSummaryInPublicHeader}
              onClick={() =>
                setPostTypeValue({
                  ...postTypeValue,
                  showSummaryInPublicHeader: !postTypeValue.showSummaryInPublicHeader,
                })
              }
            />
          </div>
        </div>
        <div className="flex mt-6">
          <div className="flex items-center">
            <label className="font-light text-black2B">Has Hero Block</label>
            <ToggleSwitch
              className="ml-4"
              checked={postTypeValue.hasHeroBlock}
              onClick={() =>
                setPostTypeValue({
                  ...postTypeValue,
                  hasHeroBlock: !postTypeValue.hasHeroBlock,
                })
              }
            />
          </div>
          <div className="flex items-center ml-6">
            <label className="font-light text-black2B">Send Newsletter</label>
            <ToggleSwitch
              className="ml-4"
              checked={postTypeValue.sendNewsletter}
              onClick={() =>
                setPostTypeValue({
                  ...postTypeValue,
                  sendNewsletter: !postTypeValue.sendNewsletter,
                })
              }
            />
          </div>
        </div>

        <label className="text-black2B font-light mt-6">Name *</label>
        <InputField
          label=""
          value={postTypeValue.name ?? ""}
          type="T"
          onChange={(value) => {
            const oldName = postTypeValue.name;
            setPostTypeValue({ ...postTypeValue, name: value || oldName });
          }}
          placeholder={`Name 1`}
        />
        <label className="text-error text-sm mt-2 ml-1">{validationError}</label>

        <label className="text-black2B  mt-6">Additional Right Content</label>

        <div className={`flex flex-col  mt-8 `}>
          <label className={`font-light text-black2B `}>Width *</label>
          <InputField
            min={10}
            label=""
            value={(postTypeValue.max_width ?? 10) as any}
            type="N"
            onChange={(value) => {
              setPostTypeValue({
                ...postTypeValue,
                max_width: Number(value) || 10,
              });
            }}
            placeholder={`Value 1`}
          />
        </div>
      </div>
    </CmsModalMainShape>
  );
}
