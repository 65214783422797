import { useEffect, useRef, useState } from "react";
import CmsModalMainShape from "../../../CmsModalMainShape";
import InputField from "../../../inputs/InputField";
import LanguageBox from "../../../LanguageBox";
import { ToggleSwitch } from "../../../layout/BlockDetailsSectionContainer";
import { PostTypeFieldType } from "./manageValue/ManagePostTypeFields";
import Dropdown from "../../../inputs/Dropdown";
import RichTextInput from "../../../inputs/RichTextInput";
import { LangValuePairType } from "../../../../utils/types";
import PublicStyle from "./PublicStyle";
import Button, { ButtonType } from "../../../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-regular-svg-icons";

interface Props {
  postTypeFieldValue?: PostTypeFieldType;
  fieldTypes: any[];
  onClose: () => void;
  onSave: (dictValue: PostTypeFieldType) => void;
  languages: Array<string>;
  defaultLanguage: string;
}

export default function AddOrEditValueModal(props: Props) {
  const PostTypeFieldDefault: PostTypeFieldType = {
    id: -1,
    isActive: true,
    showFieldInPublicHeader: false,
    showFieldInNewsletter: false,
    name: "",
    fieldType: "ST",
    order: 1000000,
    publicStyle: {},
    isUsed: false,
  };

  const [postTypeFieldValue, setPostTypeFieldValue] = useState<PostTypeFieldType>(
    props.postTypeFieldValue ?? (PostTypeFieldDefault as any)
  );
  const [language, setLanguage] = useState<string>(props.defaultLanguage);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [leftOffset, setLeftOffset] = useState(0);

  const modalContainer = useRef<HTMLDivElement>(null);

  const valueIsValid = () => {
    if (!postTypeFieldValue.name) {
      setValidationError(`Please set name `);
      return false;
    }

    return true;
  };

  const onChangeValue = (value: string) => {
    if (language === props.defaultLanguage) {
      setValidationError(null);
    }

    const newPublicStyle = { ...postTypeFieldValue.publicStyle };
    newPublicStyle[language] = value;

    setPostTypeFieldValue((_postTypeFieldValue) => ({
      ..._postTypeFieldValue,
      publicStyle: newPublicStyle,
    }));
  };

  const getRichTextData = () => {
    if (postTypeFieldValue.publicStyle) {
      const richTextData: LangValuePairType[] = [];
      for (const lang in postTypeFieldValue.publicStyle) {
        richTextData.push({
          lang,
          value: postTypeFieldValue.publicStyle[lang],
        });
      }
      return richTextData;
    }

    return [];
  };

  const onChangeNewsLetter = (value: string) => {
    if (language === props.defaultLanguage) {
      setValidationError(null);
    }

    const newNewsletterPublicStyle = { ...postTypeFieldValue.newsletterPublicStyle };
    newNewsletterPublicStyle[language] = value;

    setPostTypeFieldValue((_postTypeFieldValue) => ({
      ..._postTypeFieldValue,
      newsletterPublicStyle: newNewsletterPublicStyle,
    }));
  };

  const getNewsletterTextData = () => {
    if (postTypeFieldValue.newsletterPublicStyle) {
      const richTextData: LangValuePairType[] = [];
      for (const lang in postTypeFieldValue.newsletterPublicStyle) {
        richTextData.push({
          lang,
          value: postTypeFieldValue.newsletterPublicStyle[lang],
        });
      }
      return richTextData;
    }

    return [];
  };

  useEffect(() => {
    if (props.postTypeFieldValue)
      setPostTypeFieldValue({
        ...props.postTypeFieldValue,
        fieldType: props.postTypeFieldValue.fieldType && props.fieldTypes.find((pt) => pt.label === postTypeFieldValue.fieldType).key,
      });
  }, []);
  useEffect(() => {
    const handleResize = () => {
      modalContainer.current && setLeftOffset(modalContainer.current.getBoundingClientRect().left);
    };
    handleResize(); // Initial calculation

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [modalContainer.current]);

  return (
    <div className="fixed inset-0 bg-black/30 z-50 py-4">
      <div ref={modalContainer} className="max-w-[640px] bg-white relative bottom-0 mx-auto overflow-auto px-10 pb-8 h-screen outline-0">
        <div style={{ position: "fixed", left: leftOffset + 40, right: leftOffset + 40 }} className="bg-white  fixed z-50 pt-4">
          <div className="flex justify-between items-center">
            <h1 className="flex flex-1 font-bold">Add/Edit Field</h1>
            <FontAwesomeIcon className="text-[#504F4E] cursor-pointer" icon={faClose} onClick={props.onClose} />
          </div>
          <div>
            <div className="w-full mt-6">
              <div className="flex items-center">
                <label className="font-light text-black2B">Active</label>
                <ToggleSwitch
                  className="ml-4"
                  checked={postTypeFieldValue.isActive}
                  onClick={() =>
                    setPostTypeFieldValue({
                      ...postTypeFieldValue,
                      isActive: !postTypeFieldValue.isActive,
                    })
                  }
                />
              </div>
            </div>
            <Dropdown
              className="w-full mt-6"
              label="Field Type *"
              onOptionSelect={(selectedOption) =>
                !postTypeFieldValue.isUsed &&
                setPostTypeFieldValue({
                  ...postTypeFieldValue,
                  fieldType: selectedOption.key as string,
                } as any)
              }
              options={props.fieldTypes}
              selectedOption={
                props.postTypeFieldValue && props.postTypeFieldValue.fieldType
                  ? props.fieldTypes.find((pt) => pt.key === postTypeFieldValue.fieldType)!
                  : props.fieldTypes[0]
              }
              disabled={postTypeFieldValue.isUsed}
            />
          </div>
          <label className="text-black2B font-light mt-4 inline-block">Name *</label>
          <div className="flex justify-between flex-wrap w-full">
            <InputField
              label=""
              value={postTypeFieldValue.name ?? ""}
              type="T"
              onChange={(value) => {
                const oldName = postTypeFieldValue.name;
                setPostTypeFieldValue({
                  ...postTypeFieldValue,
                  name: value || oldName,
                });
              }}
              placeholder={`Name 1`}
            />
            <LanguageBox
              className="h-[41.6px] mt-4"
              languagesArr={props.languages}
              defaultLang={props.defaultLanguage}
              onChange={setLanguage}
            />
          </div>
          <div className="w-full h-1 bg-secondary-100 mt-2" />
        </div>

        <div className="flex items-start flex-col mt-72">
          <label className="text-error text-sm mt-2 ml-1">{validationError}</label>

          <PublicStyle
            toggleSwitchValue={postTypeFieldValue.showFieldInPublicHeader}
            getRichTextData={() => getRichTextData()}
            language={language}
            onChangeValue={(data) => onChangeValue(data)}
            toggleSwitchHandler={() =>
              setPostTypeFieldValue({
                ...postTypeFieldValue,
                showFieldInPublicHeader: !postTypeFieldValue.showFieldInPublicHeader,
              })
            }
            switchLabel="Show In Header"
          />

          <PublicStyle
            toggleSwitchValue={postTypeFieldValue.showFieldInNewsletter}
            getRichTextData={() => getNewsletterTextData()}
            language={language}
            onChangeValue={(data) => onChangeNewsLetter(data)}
            toggleSwitchHandler={() =>
              setPostTypeFieldValue({
                ...postTypeFieldValue,
                showFieldInNewsletter: !postTypeFieldValue.showFieldInNewsletter,
              })
            }
            switchLabel="Show In Newsletter"
          />
        </div>
        <div>
          <div className="flex justify-center mt-8">
            <Button className="text-gray border-[#C4C4C4] mr-4" type={ButtonType.SECONDARY} onClick={props.onClose}>
              Cancel
            </Button>

            <Button
              type={ButtonType.PRIMARY}
              onClick={() => {
                if (valueIsValid()) {
                  props.onSave(postTypeFieldValue);
                }
              }}
              // showLoading={props.loading}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
    // <CmsModalMainShape
    //   title={"Add/Edit Field"}
    //   positiveBtnTitle={"Save"}
    //   showModal={true}
    //   onNegativeBtnClick={props.onClose}
    //   onPositiveBtnClick={() => {
    //     if (valueIsValid()) props.onSave(postTypeFieldValue);
    //   }}
    //   rootStyle={{ maxWidth: 640 }}
    // >
    //   <div className="flex items-start flex-col">
    //     <div className="bg-orange w-full">
    //       <div className="w-full mt-6">
    //         <div className="flex items-center">
    //           <label className="font-light text-black2B">Active</label>
    //           <ToggleSwitch
    //             className="ml-4"
    //             checked={postTypeFieldValue.isActive}
    //             onClick={() =>
    //               setPostTypeFieldValue({
    //                 ...postTypeFieldValue,
    //                 isActive: !postTypeFieldValue.isActive,
    //               })
    //             }
    //           />
    //         </div>
    //       </div>
    //       <Dropdown
    //         className="w-full mt-6"
    //         label="Field Type *"
    //         onOptionSelect={(selectedOption) =>
    //           !postTypeFieldValue.isUsed &&
    //           setPostTypeFieldValue({
    //             ...postTypeFieldValue,
    //             fieldType: selectedOption.key as string,
    //           } as any)
    //         }
    //         options={props.fieldTypes}
    //         selectedOption={
    //           props.postTypeFieldValue && props.postTypeFieldValue.fieldType
    //             ? props.fieldTypes.find((pt) => pt.key === postTypeFieldValue.fieldType)!
    //             : props.fieldTypes[0]
    //         }
    //         disabled={postTypeFieldValue.isUsed}
    //       />
    //     </div>

    //     <label className="text-black2B font-light mt-6">Name *</label>
    //     <div className="flex justify-between w-full">
    //       <InputField
    //         label=""
    //         value={postTypeFieldValue.name ?? ""}
    //         type="T"
    //         onChange={(value) => {
    //           const oldName = postTypeFieldValue.name;
    //           setPostTypeFieldValue({
    //             ...postTypeFieldValue,
    //             name: value || oldName,
    //           });
    //         }}
    //         placeholder={`Name 1`}
    //       />
    //       <LanguageBox className="h-[41.6px] mt-4" languagesArr={props.languages} defaultLang={language} onChange={setLanguage} />
    //     </div>
    //     <div className="w-full h-1 bg-secondary-100 mt-2" />

    //     <label className="text-error text-sm mt-2 ml-1">{validationError}</label>

    //     <PublicStyle
    //       getRichTextData={() => getRichTextData()}
    //       language={language}
    //       onChangeValue={(data) => onChangeValue(data)}
    //       postTypeFieldValue={postTypeFieldValue}
    //       setPostTypeFieldValue={() =>
    //         setPostTypeFieldValue({
    //           ...postTypeFieldValue,
    //           showFieldInPublicHeader: !postTypeFieldValue.showFieldInPublicHeader,
    //         })
    //       }
    //       switchLabel="Show Header"
    //     />

    //     <PublicStyle
    //       getRichTextData={() => getRichTextData()}
    //       language={language}
    //       onChangeValue={(data) => onChangeValue(data)}
    //       postTypeFieldValue={postTypeFieldValue}
    //       setPostTypeFieldValue={() =>
    //         setPostTypeFieldValue({
    //           ...postTypeFieldValue,
    //           showFieldInPublicHeader: !postTypeFieldValue.showFieldInPublicHeader,
    //         })
    //       }
    //       switchLabel="Show Newsletter"
    //     />
    //   </div>
    // </CmsModalMainShape>
  );
}
